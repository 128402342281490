import React from 'react';
import './GoodList.css'; // Подключаем файл стилей

const GoodList = ({ products, onProductEdit }) => {
  return (
    <div className="product-list-container">
      <table className="product-table">
        <thead>
          <tr>
            <th>Штрихкод</th>
            <th>Артикул</th>
            <th>Наименование</th>
            <th>Вес</th>
            <th>Объем</th>
            <th>Себестоимость</th>
            <th>Реклама</th>
            <th>Выкуп WB</th>
            <th>Выкуп OZON</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(products) && products.map(product => (
            <tr key={product.id}>
              <td>{product.barcode}</td>
              <td>{product.offerId}</td>
              <td>{product.name}</td>
              <td>{product.weight} кг</td>
              <td>{product.width}x{product.height}x{product.lenght}  м</td>
              <td>{product.sellerCost} р</td>
              <td>{product.adsCostBuy} р</td>
              <td>{product.wbBuyOut} %</td>
              <td>{product.ozonBuyout} %</td>
              <td>
                <button className="open-button" onClick={()=>{onProductEdit(product)}}>Изменить</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default GoodList;