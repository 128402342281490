import React, { useState, useEffect } from 'react';
import './GoodPopup.css';

const GoodUploadPopup = ({ isOpen, isEdit, existedObject, onClose, onSubmitClicked }) => {
  const [name, setName] = useState('');
  const [barcode, setBarcode] = useState('');
  const [offerId, setOfferId] = useState('');
  const [sellerCost, setSellerCost] = useState('');
  const [weight, setWeight] = useState('');
  const [volume, setVolume] = useState('');
  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('');
  const [lenght, setLenght] = useState('');
  const [adsCost, setAdsCost] = useState('');
  const [wbBuyout, setWbBuyout] = useState('');
  const [ozonBuyout, setOzonBuyout] = useState('');
  const [productId, setProductId] = useState(-1);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Проверка пустых значений
  if (name.length === 0 || 
    barcode.length === 0 || 
    offerId.length === 0 || 
    sellerCost.length === 0 || 
    weight.length === 0 || 
    lenght.length === 0 || 
    adsCost.length === 0 || 
    width.length === 0 || 
    wbBuyout.length === 0 || 
    ozonBuyout.length === 0 || 
    height.length === 0) {
    alert('Пожалуйста, заполните все поля');
    return;
  }

  // Проверка формата
  if (isNaN(parseFloat(sellerCost)) || isNaN(parseFloat(weight)) || isNaN(parseFloat(width)) || isNaN(parseFloat(height)) || isNaN(parseFloat(lenght)) || isNaN(parseFloat(adsCost))|| isNaN(parseFloat(wbBuyout)) || isNaN(parseFloat(ozonBuyout))) {
    alert('Пожалуйста, убедитесь, что поля "Себестоимость", "Вес" и "Объем" содержат числовые значения');
    return;
  }

    onSubmitClicked({productId, name, barcode, offerId, sellerCost, weight, volume, width, height, lenght, adsCost, wbBuyout, ozonBuyout});
    onClose();
  };

  useEffect(() => {
    if (isEdit === true && existedObject) {
      setName(existedObject.name);
      setBarcode(existedObject.barcode);
      setOfferId(existedObject.offerId);
      setSellerCost(existedObject.sellerCost);
      setVolume(existedObject.volume);
      setWeight(existedObject.weight);
      setWidth(existedObject.width);
      setLenght(existedObject.lenght);
      setHeight(existedObject.height);
      setAdsCost(existedObject.adsCostBuy);
      setWbBuyout(existedObject.wbBuyOut);
      setOzonBuyout(existedObject.ozonBuyout);
      setProductId(existedObject.idProduct);
    }
    else
    {
        setName("");
        setBarcode("");
        setOfferId("");
        setSellerCost("");
        setVolume("");
        setWeight("");
        setWidth("");
        setLenght("");
        setHeight("");
        setAdsCost("");
        setWbBuyout("");
        setOzonBuyout("");
        setProductId(-1)
    }
  }, [isEdit, existedObject]);

  return (
    <div className={`good-upload-popup ${isOpen ? 'open' : ''}`}>
      <div className="good-upload-popup-content">
        <span className="good-upload-close-button" onClick={onClose}>X</span>
        <form onSubmit={handleSubmit}>
          <div className="good-upload-form-group">
            <label htmlFor="name" className="good-upload-label">Наименование:</label>
            <input type="text" id="name" className="good-upload-input" value={name} onChange={(e) => setName(e.target.value)} />
          </div>
          <div className="good-upload-form-group">
            <label htmlFor="barcode" className="good-upload-label">Штрихкод:</label>
            <input type="text" id="barcode" className="good-upload-input" value={barcode} onChange={(e) => setBarcode(e.target.value)} />
          </div>
          <div className="good-upload-form-group">
            <label htmlFor="offerId" className="good-upload-label">Артикул:</label>
            <input type="text" id="offerId" className="good-upload-input" value={offerId} onChange={(e) => setOfferId(e.target.value)} />
          </div>
          <div className="good-upload-form-group">
            <label htmlFor="sellerCost" className="good-upload-label">Себестоимость:</label>
            <input type="text" id="sellerCost" className="good-upload-input" value={sellerCost} onChange={(e) => setSellerCost(e.target.value)} />
          </div>
          <div className="good-upload-form-group">
            <label htmlFor="weight" className="good-upload-label">Вес:</label>
            <input type="text" id="weight" className="good-upload-input" value={weight} onChange={(e) => setWeight(e.target.value)} />
          </div>
          <div className="good-upload-form-group">
            <label htmlFor="Width" className="good-upload-label">Ширина:</label>
            <input type="text" id="Width" className="good-upload-input" value={width} onChange={(e) => setWidth(e.target.value)} />
          </div>
          <div className="good-upload-form-group">
            <label htmlFor="Height" className="good-upload-label">Высота:</label>
            <input type="text" id="Height" className="good-upload-input" value={height} onChange={(e) => setHeight(e.target.value)} />
          </div>
          <div className="good-upload-form-group">
            <label htmlFor="Lenght" className="good-upload-label">Длина:</label>
            <input type="text" id="Lenght" className="good-upload-input" value={lenght} onChange={(e) => setLenght(e.target.value)} />
          </div>
          <div className="good-upload-form-group">
            <label htmlFor="adsCost" className="good-upload-label">Цена рекламы:</label>
            <input type="text" id="adsCost" className="good-upload-input" value={adsCost} onChange={(e) => setAdsCost(e.target.value)} />
          </div><div className="good-upload-form-group">
            <label htmlFor="adsCost" className="good-upload-label">Процент выкупа OZON:</label>
            <input type="text" id="adsCost" className="good-upload-input" value={wbBuyout} onChange={(e) => setWbBuyout(e.target.value)} />
          </div><div className="good-upload-form-group">
            <label htmlFor="adsCost" className="good-upload-label">Процент выкупа WB:</label>
            <input type="text" id="adsCost" className="good-upload-input" value={ozonBuyout} onChange={(e) => setOzonBuyout(e.target.value)} />
          </div>
          <div className="good-button-group">
          <button type="submit" className="good-upload-button">OK</button>
          <button type="button" className="good-upload-button" onClick={onClose}>CLOSE</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default GoodUploadPopup;